<template>
  <div :key="shouldFooterBeingVisible" class="sign-up">
    <router-view />
    <div v-if="shouldFooterBeingVisible" class="admin-footer">
      <router-link to="/" :aria-label="t('ariaLabel.home')" class="logo-link">
        <AppIcon name="switch-logo-full" :opacity="0.3" size="116px" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue';

import { apiHealthCheck } from '@/api/auth';
import { useRoute } from '@/router';

export default defineComponent({
  name: 'AdminAuth',
  setup() {
    const route = useRoute();
    const currentPath = computed(() => route.path);
    const shouldFooterBeingVisible = computed(() => !currentPath.value.includes('admin-link-expired'));

    onBeforeMount(() => { apiHealthCheck(); });

    return { shouldFooterBeingVisible };
  },
});
</script>

<style scoped lang="scss">
.sign-up {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 100px;
}

.admin-footer {
  margin-top: 35px;
  @include flex-center;

  :deep(.icon) {
    cursor: pointer;
    height: auto;
    padding: 10px;
    @include transition-base('opacity');

    &:hover {
      opacity: 1;
    }
  }
}
</style>
